import Header from "./components/common/header";
import { Routes, Route, Link } from "react-router-dom";
import Index from "./components/index";
import Auth from "./components/auth";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div class="body-container">
        <Header />
        <div class="container">
          <main role="main" class="p-3 mt-5">
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/authorise" element={<Auth />} />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
