export const msalConfig = {
    auth: {
      clientId: "5cc223e8-6a56-46b0-98ef-b6db1a5b37be",
      authority: "https://login.microsoftonline.com/255e6cd5-dcfb-4c4c-9698-19bade1d2f33", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://mfa.westminster.sa.edu.au/authorise",
      
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };