import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
export default(() => {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const name = accounts[0] && accounts[0].name;

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).then((r) => {
        console.log(r);        
      }).catch((e) => {
        console.error(e);
      });
    }
  }, []);


  return (
    <><UnauthenticatedTemplate >Loading...</UnauthenticatedTemplate >
    <AuthenticatedTemplate>
          <h1 className="display-6 p-0 my-3">Multi-factor Authentication</h1>
          <div className="align-items-center justify-content-center w-100">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
          <h2 className="p-0 mt-5 mb-5 text-center">
            Congratulations, you are now registered for MFA!
          </h2>
          <p className="text-center">
            <a className="btn btn-light text-center btn-lg" href="/">
              &lt; Back to instructions
            </a>
          </p>
        </AuthenticatedTemplate>
    </>
  );
});
