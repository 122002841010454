import React from "react";



export default () => {
    
  return (
    <div className="text-left">
      <h1 className="display-6 p-0 my-3">Multi-factor Authentication</h1>
      <p>
        Multi-Factor Authentication (MFA) is part of Westminster School's
        response to the increased threat of cyber-crime to educational
        institutions. MFA ensures that our accounts are protected with multiple
        layers of security and helps protect our systems and data against a
        variety of attacks and a Microsoft report showed that MFA can prevent
        over 99.9% of account compromise attacks.
      </p>
      <p>MFA gives your account an additional layer of protection by requiring something you know (your password) and something you have (your phone).</p>
      <p>The School's insurer requires all staff to have MFA activated by <b>31st January 2022</b>.</p>
      <hr className="mt-5" />
      <h2 className="p-0 mt-5 mb-3">Setting up MFA on your account</h2>

      <p>Click the link below to log in and setup MFA. This will open the sign in page in a new tab, so you can continue to refer to these instructions.</p>
      <a
        className="btn btn-block btn-lg btn-primary my-5"
        href="/authorise"
        target="_blank"
        
      >
        Sign in to register MFA
      </a>
      <p>Log in as usual using your Westminster email address and password</p>
      <p>
        Once you have successfully logged in, the next screen will ask you for
        more information:
        <br />
        <br />
        <img
          src="/assets/images/mfa-more-info.png"
          alt="More Information Screen"
          className="img-fluid"
        />
        <br />
        <br />
        Click <b>Next</b>, and on the next screen, select <b>Authentication phone</b> from the drop
        down menu, select <b>Australia (+61)</b> and then enter your mobile
        phone number:
        <span className="alert alert-danger d-block my-5">
          <b>
            Do not select office phone otherwise you will need to be on campus
            in order to authenticate
          </b>
        </span>
        <img
          src="/assets/images/mfa-add-textmessage.png"
          alt="Enter MFA details"
          className="img-fluid"
        />
        <br />
        <br />
        Click the <b>Next</b> button.<br/>
        You will now receive a SMS message with a numeric code to enter, enter
        this number into the next screen:
        <br />
        <br />
        <img
          src="/assets/images/mfa-enter-sms-code.png"
          alt="Enter your SMS code"
          className="img-fluid"
        />
        <br />
        <br />
        Once your SMS code has been confirmed, you will be redirected back to a
        success page.
        <br />
        <br />
        From now on, you will be asked to complete MFA when signing in to your
        account from outside of the Westminster School network. You will be
        asked to complete MFA once ever 90 days on each device.
        <hr class="mt-5" />
        <h2 class="p-0 mt-5 mb-3">Signing in using MFA</h2>
        <br />
        When logging in from outside of the School network, you will be asked to
        confirm your identity to the mobile phone you registered above once
        every 90 days per device.
        <br />
        <br />
        First, sign in as usual using your Westminster School email address and
        password. You will then be asked to confirm your identity to the mobile
        phone you registered previously. Select either text or call, which ever
        is the most convenient for you:
        <br />
        <br />
        <img
          src="/assets/images/mfa-sign-in.png"
          alt="Signing In"
          className="img-fluid"
        />
        <br />
        <br />
        Once you have recieved a call or a text message, enter the code in the
        next screen to complete a MFA sign in:
        <br />
        <br />
        <img
          src="/assets/images/mfa-sign-in-code.png"
          alt="Signing In"
          className="img-fluid"
        />
        <br />
        <br />
      </p>
    </div>
  );
};
