import React from 'react';

export default () => {
    return <header className="py-3">
                <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light mb-3 py-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <img src="/assets/images/logo-westminster-primary.svg" alt="Westminster School" />
                            </div>
                        </div>

                    </div>
                    <div className="content">
                    </div>
                </nav>
            </header>
}